<script>
  import { onMount, afterUpdate } from "svelte";
  import Chart from "chart.js";

  export let class_name = "";
  export let raw_data = "";

  let chart;
  let ref = null;

  const parseData = () => {
    try {
      return JSON.parse(raw_data);
    } catch (e) {
      return { datasets: [], labels: [] };
    }
  };

  onMount(() => {
    chart = new Chart(ref, {
      type: "pie",
      data: parseData(),
      options: {
        legend: {
          display: false,
          position: "bottom",
        },
      },
    });
  });

  afterUpdate(() => {
    if (!chart) return;
    chart.data = parseData();
    chart.update();
  });
</script>

<style>
  @import "/stylesheets/tailwindcss.css";
</style>

<svelte:options tag="skill-chart" immutable={true} />
<div class={class_name}><canvas class="w-full h-full" bind:this={ref} /></div>
